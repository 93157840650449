import type { Interaction, ValueMappingKeys } from '../types';

import { emitUpdateSelectOnPageInteraction } from '@/modules/editor/modules/preview/utils/emitToIframe';
import { composableWrite, compose } from '../helpers';
import useInteractionStore from '../stores';
import { useBuildDataMapping } from './useBuildData';
import { ControlType, TriggerEvent } from '../constants';
import { ID } from '@/utils/id';

export const useInteractionData = () => {
  const interactionStore = useInteractionStore();

  const { getTriggerEvents, getTargetEvents } = useBuildDataMapping();

  const trigger = computed(() => interactionStore.currentInteractionTrigger);
  const targets = computed(() => interactionStore.currentInteractionTargets);

  const setCurrentInteractionTrigger = (data: Record<string, string | boolean>) => {
    const eventCurrent = trigger.value?.event;
    const { elementTag } = data || {};
    const triggerEvents = getTriggerEvents(elementTag as string);
    const eventExists = triggerEvents.map((event) => event.id).includes(eventCurrent ?? TriggerEvent.EMPTY);
    if (!elementTag || eventExists) {
      interactionStore.updateInteractionTrigger({
        ...(trigger.value || {}),
        ...data,
        condition: {},
      });
      return;
    }
    interactionStore.updateInteractionTrigger({
      ...(trigger.value || {}),
      ...data,
      controlType: ControlType.EMPTY,
      event: TriggerEvent.EMPTY,
      rollbackEvent: {
        event: '',
      },
      condition: {},
    });
  };

  const setCurrentInteractionTarget = (data: Record<string, any>, currentTargetId: string) => {
    if (!currentTargetId) {
      return;
    }
    const { elementTag, type } = data || {};
    if (type === 'PAGE') {
      interactionStore.updateInteractionTargetAttribute({
        currentTargetId: currentTargetId || '0',
        data: {
          ...data,
          events: [],
        },
      });
      return;
    }
    if (!elementTag) {
      interactionStore.updateInteractionTargetAttribute({
        currentTargetId: currentTargetId || '0',
        data,
      });
      return;
    }
    const targetEventsByElementTag = getTargetEvents(elementTag).map((el) => el.id);
    const targetEventsCurr = targets.value
      .find((el) => el.id === currentTargetId)
      ?.events?.filter((el) => targetEventsByElementTag.includes(el.controlId));
    interactionStore.updateInteractionTargetAttribute({
      currentTargetId: currentTargetId || '0',
      data: {
        ...data,
        events: targetEventsCurr,
      },
    });
  };

  const addEmptyTarget = () => {
    const isExitEmptyTarget = interactionStore.currentInteraction?.targets?.find(
      (item) => !item.uid && item.type === 'ELEMENT',
    );

    if (!isExitEmptyTarget) {
      const id = ID();
      interactionStore.updateInteractionTargets({
        id,
      });
      interactionStore.setIsSelectOnPageForTarget(true);
      interactionStore.setIsSelectOnPageForTrigger(false);
      interactionStore.setCurrentTargetId(id);
      emitUpdateSelectOnPageInteraction(true, undefined, { mode: 'ELEMENT', settingType: 'TARGET' });
    }
  };

  const getDefaultValueByEvent = (_value: string) => {
    const result = getTriggerEvents(trigger.value?.elementTag || '', trigger.value?.type);
    const option = result.find((el) => el.value === _value);
    const defaultValue = Object.entries(option?.default ?? {}).reduce(
      (acc, [_, value]: [string, any]) => composableWrite(value.mappingKeys, value.value)(acc),
      {},
    );
    return defaultValue;
  };

  const updateInteractionTrigger = (data: ValueMappingKeys[], defaultValue?: any) => {
    if (!trigger.value) return;
    const currentValue = compose(...data.map((el) => composableWrite(el.mappingKeys, el.value)))(trigger.value);
    const newVal = {
      ...currentValue,
      ...defaultValue,
    };
    interactionStore.setIsSelectOnPageForTrigger(false);
    emitUpdateSelectOnPageInteraction(false);
    interactionStore.updateInteractionTrigger(newVal);
  };

  const handleRemoveEmptyTarget = (interactionList: Interaction[]): Interaction[] => {
    return interactionList?.map((item) => ({
      ...item,
      targets: item.targets?.filter((target) => target.uid || target.type === 'PAGE'),
    }));
  };

  return {
    setCurrentInteractionTrigger,
    setCurrentInteractionTarget,
    getDefaultValueByEvent,
    updateInteractionTrigger,
    addEmptyTarget,
    handleRemoveEmptyTarget,
  };
};
