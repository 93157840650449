import { usePageInteractionCreateMutation } from '@/api/app/mutations/create-interaction-page.generated';
import { usePageInteractionUpdateMutation } from '@/api/app/mutations/update-interaction-page.generated';
import useInteractionStore from '../stores';
import useEditorStore from '@/modules/editor/modules/common/stores/editor';
import hash from 'object-hash';
import { emitUpdateSelectOnPageInteraction } from '@/modules/editor/modules/preview/utils/emitToIframe';
import { usePageInteractionPreviewMutation } from '@/api/app/mutations/updatePreviewInteraction.generated';
import { useMode } from '@/composables/useMode';
import { useSaleFunnel } from '@/modules/editor/modules/sale-funnels/hooks/useSaleFunnel';
import { useInteractionData } from './useInteractionData';
import { logInteractionError } from '../utils';

export const useCRUDInteraction = () => {
  const pageInteractionCreateMutation = usePageInteractionCreateMutation();
  const pageInteractionUpdateMutation = usePageInteractionUpdateMutation();
  const pageInteractionPreview = usePageInteractionPreviewMutation();
  const editorStore = useEditorStore();
  const { isThemeSectionEditor } = useMode();
  const { isPostPurchasePage } = useSaleFunnel();
  const interactionStore = useInteractionStore();
  const { handleRemoveEmptyTarget } = useInteractionData();

  const pageId = computed(() => editorStore.getEditingPageId);
  const pageInteractions = computed(() => interactionStore.getPageInteraction);
  const currentInteraction = computed(() => interactionStore.currentInteractionData);
  const hashData = computed(() => interactionStore.getHashData);
  const pageType = computed(() => editorStore.editing.pageType);
  const isEditingThemeSection = computed(() => editorStore.editing.isEditThemeSection);
  const isBlockInteraction = computed(() => {
    return pageType.value === 'POST_PURCHASE' || isEditingThemeSection.value;
  });

  const interactionHasChange = computed(() => {
    if (isThemeSectionEditor.value || isPostPurchasePage.value) {
      return false;
    }
    const newHash = hash.MD5(pageInteractions.value?.interactionList || []);
    return hashData.value !== newHash;
  });

  const createInteraction = async () => {
    if (pageInteractions.value.interactionList.length === 0) return true;

    try {
      const data = await pageInteractionCreateMutation.mutateAsync({
        input: {
          value: handleRemoveEmptyTarget(pageInteractions.value.interactionList),
          pageID: pageId.value,
        },
      });
      const hashCompare = hash.MD5(pageInteractions.value?.interactionList || []);
      interactionStore.setHashData(hashCompare);
      await pageInteractionPreview.mutateAsync({
        pageInteractionPreviewId: data.pageInteractionCreate?.id || '',
      });

      if (data.pageInteractionCreate?.id) {
        interactionStore.setPageInteractions({
          id: data.pageInteractionCreate?.id,
          interactionList: data.pageInteractionCreate?.value || [],
        });
        const changedContinue = checkChangeWhenUpdated(hashCompare);
        if (changedContinue) {
          editorStore.setAutoSaveEnable(true);
          return;
        }
      }
    } catch (error) {
      logInteractionError('createInteraction', `${JSON.stringify(error)}`, { error });
    }
  };

  const isExitsCurrentInteraction = computed(() => {
    return pageInteractions.value?.interactionList.some(
      (interaction) => interaction.id === currentInteraction.value?.id,
    );
  });

  const updateInteraction = async () => {
    try {
      if (!pageInteractions.value.id) return;
      const newInteractions = isExitsCurrentInteraction
        ? pageInteractions.value?.interactionList.map((interaction) => {
            return interaction.id === currentInteraction.value?.id ? currentInteraction.value || {} : interaction;
          })
        : pageInteractions.value?.interactionList;
      const data = await pageInteractionUpdateMutation.mutateAsync({
        pageInteractionUpdateId: pageInteractions.value.id,
        input: {
          value: handleRemoveEmptyTarget(newInteractions || []),
        },
      });
      if (data.pageInteractionUpdate?.id) {
        const hashCompare = hash.MD5(newInteractions);
        interactionStore.setHashData(hashCompare);
        await pageInteractionPreview.mutateAsync({
          pageInteractionPreviewId: data.pageInteractionUpdate?.id || '',
        });
        const changedContinue = checkChangeWhenUpdated(hashCompare);
        if (changedContinue) {
          editorStore.setAutoSaveEnable(true);
          return;
        }
      }
    } catch (error) {
      logInteractionError('updateInteraction', `${JSON.stringify(error)}`, { error });
    }
  };

  const checkChangeWhenUpdated = (hashCompare: string) => {
    const localDataHash = hash.MD5(pageInteractions.value?.interactionList || []);
    return localDataHash !== hashCompare;
  };

  const upsertInteraction = async () => {
    if (!interactionHasChange.value || isBlockInteraction.value) return;

    if (pageInteractions.value?.id) {
      await updateInteraction();
    } else {
      await createInteraction();
    }
  };

  const addInteraction = (type: 'PAGE' | 'ELEMENT', isSelectedOnPage: boolean = true) => {
    interactionStore.addInteraction({
      self: {
        type,
      },
    });
    if (isSelectedOnPage && type !== 'PAGE') {
      emitUpdateSelectOnPageInteraction(true, undefined, {
        mode: type ?? 'PAGE',
        settingType: 'TRIGGER',
      });
      interactionStore.setIsSelectOnPageForTrigger(true);
    }
  };

  return {
    upsertInteraction,
    addInteraction,
    isBlockInteraction,
    interactionHasChange,
  };
};
