import type { Component } from '@gem/common';
import type {
  InteractionTargetEvent,
  InteractionCondition,
  InteractionTargetEventObject,
  Interaction,
  InteractionTrigger,
  InteractionTarget,
} from '../types';
import { ConditionQuantity } from '../types';
import { ControlType, DEFAULT_BG_TARGET, RollbackEvent, TOGGLE_DISABLED_EVENTS, TriggerEvent } from '../constants';

const write = (obj: any, keys: (string | number)[], v: any) => {
  if (obj === undefined) obj = {};
  if (keys.length === 0) {
    return v;
  }
  if (keys.length === 1) {
    obj[keys[0]] = v;
  } else {
    const [key, ...remainingKeys] = keys;
    const nextKey = remainingKeys[0];
    const nextRemainingKeys = remainingKeys.slice(1);

    if (typeof nextKey === 'number') {
      // create array
      if (!obj[key]) {
        obj[key] = [];
      }

      // Fill empty index with empty object
      if (obj[key].length < nextKey + 1) {
        const delta = nextKey + 1 - obj[key].length;
        for (let i = 0; i < delta; i++) {
          obj[key].push({});
        }
      }

      // recursively write the object
      obj[key][nextKey] = write(obj[key][nextKey], nextRemainingKeys, v);
    } else {
      // If the key exists and is not an object, replace it with an object
      if (typeof obj[key] !== 'object' || obj[key] === null) {
        obj[key] = {};
      }

      // Recursively write the object
      obj[key] = write(obj[key], remainingKeys, v);
    }
  }

  return obj;
};

const read = (obj: any, path: string[]) => {
  let o = obj;
  for (const p of path) {
    if (o === undefined || o === null) {
      return undefined; // Return undefined if the path does not exist
    }
    o = o[p];
  }
  return o;
};

const merge = (a: any, b: any, fn: any) =>
  [...new Set([...Object.keys(a), ...Object.keys(b)])].reduce(
    (acc, key) => ({ ...acc, [key]: fn(key, a[key], b[key]) }),
    {},
  );

const concatFn = (key: any, a: any, b: any) => {
  if (Array.isArray(a) && Array.isArray(b)) return a.concat(b);
  if (typeof a === 'object' && typeof b === 'object') return merge(a, b, concatFn);
  if (typeof a === 'string' && typeof b === 'string') return b;
  return b ?? a;
};

export const compose = <T>(...functions: Array<(a: T) => T>) => {
  return (input: T) => {
    return functions.reduceRight((acc, fn) => {
      return fn(acc);
    }, input);
  };
};

export const composableMerge = (source: any, target: any) => merge(source, target, concatFn);

export const composableRead = (path: string[]) => (obj: any) => read(obj, path);
export const composableWrite = (path: (string | number)[], value: any) => (object: any) => write(object, path, value);

const getChangeExpandedItem = (condition?: InteractionCondition) => {
  const data = condition?.data;
  if (data !== null && data !== undefined) return `Expand item ${Number(data) + 1}`;
  return 'No change';
};

const getShowHideSubInfo = (condition?: InteractionCondition) => {
  if (!condition) return 'No change';

  const data = condition?.data;
  const animationEnable = condition.metaData?.animation?.enabled;

  switch (data) {
    case 'hidden':
      return animationEnable ? 'Hide with animation' : 'Hide without animation';
    case 'appearByTrigger':
      return animationEnable ? 'Show with animation' : 'Show without animation';
    default:
      return 'No change';
  }
};

const getScrollToSubInfo = (condition?: InteractionCondition) => {
  if (!condition || !condition.data) return 'No change';

  return `Stop if distance to top is ${condition.data}`;
};

const getChangeImageSubInfo = (condition?: InteractionCondition, componentData?: Component | null) => {
  if (!condition || !condition.data) return 'No change';

  const defaultValue = getDefaultValueForImageTarget({
    componentSettings: componentData?.settings as Record<string, any>,
    componentStyles: componentData?.styles as Record<string, any>,
  });
  if (JSON.stringify(condition?.data?.bgi?.src) !== JSON.stringify(defaultValue?.bgi?.src)) {
    return 'Change to new image';
  }
  if (condition?.data?.bgSize !== defaultValue.bgSize) {
    return 'Change image scale';
  }
  return 'No change';
};

const getHeroBannerSubInfo = (condition?: InteractionCondition, componentData?: Component | null) => {
  if (!condition) return 'No change';
  const defaultValue = componentData?.styles?.background?.desktop;
  if (
    condition?.data?.color !== defaultValue?.color ||
    condition?.data?.image?.src !== defaultValue?.image?.src ||
    condition?.data?.type !== defaultValue?.type
  ) {
    return 'Change to new banner';
  }
  if (condition.data?.type === 'image' && defaultValue?.size !== condition?.data?.size) return 'Change image scale';
  return 'No change';
};

const getContentSubInfo = (condition?: InteractionCondition) => {
  if (!condition?.data) return 'No change';
  return 'Change to new content';
};

const getTypographySubInfo = (
  condition?: InteractionCondition,
  componentData?: Component | null,
  componentTag?: string,
) => {
  if (!condition || !componentData) return 'No change';
  const defaultValue = getDefaultValueForTextTarget({
    componentStyles: componentData.styles as Record<string, any>,
    componentSettings: componentData.settings as Record<string, any>,
    componentTag: componentTag as string,
  });
  if (JSON.stringify(condition.data) !== JSON.stringify(defaultValue)) return 'Change setting';

  return 'No change';
};

const getChangeImageStepSubInfo = (condition?: InteractionCondition) => {
  if (!condition || !condition.data) return 'No change';

  return `Show image ${parseInt(condition.data + 1, 10) || 0 + 1}`;
};

const getChangeTogglePopup = () => {
  return 'Open';
};

const getColorShapeSubInfo = (
  condition?: InteractionCondition,
  componentData?: Component | null,
  componentTag?: string,
) => {
  if (!condition || !componentData) return 'No change';
  const defaultValue = getDefaultValueForBgTarget({
    componentStyles: componentData.styles as Record<string, any>,
    componentTag: componentTag as string,
  });
  if (JSON.stringify(condition.data) !== JSON.stringify(defaultValue)) return 'Change settings';
  return 'No change';
};

export const composeTargetSubInfo = (
  targetEvents: InteractionTargetEventObject[],
  componentData?: Component | null,
  componentTag?: string,
) => {
  if (!targetEvents?.length) return 'No change';
  if (targetEvents.length > 1) return 'Change multiples setting';

  const firstEvent = targetEvents[0];
  if (!firstEvent.event) return 'No change';

  const subInfoMap = new Map<InteractionTargetEvent, () => string>([
    ['gp:show-or-hide', () => getShowHideSubInfo(firstEvent.condition)],
    ['gp:scroll-to', () => getScrollToSubInfo(firstEvent.condition)],
    ['gp:change-content', () => getContentSubInfo(firstEvent.condition)],
    ['gp:change-image', () => getChangeImageSubInfo(firstEvent.condition, componentData)],
    ['gp:change-banner', () => getHeroBannerSubInfo(firstEvent.condition, componentData)],
    ['gp:change-text-style', () => getTypographySubInfo(firstEvent.condition, componentData, componentTag)],
    ['gp:change-color-shape', () => getColorShapeSubInfo(firstEvent.condition, componentData, componentTag)],
    ['gp:change-image-step', () => getChangeImageStepSubInfo(firstEvent.condition)],
    ['gp:change-expanded-item', () => getChangeExpandedItem(firstEvent.condition)],
    ['gp:toggle-popup-open', () => 'Open popup'],
    ['gp:toggle-popup-close', () => 'Close popup'],
    ['gp:change-next-slide', () => 'Next slide'],
    ['gp:change-previous-slide', () => 'Previous slide'],
  ]);

  const getTargetSubInfoFunction = subInfoMap.get(firstEvent.event);

  return getTargetSubInfoFunction ? getTargetSubInfoFunction() : 'No change';
};

export const composeTargetEventName = (eventName: string) => {
  const eventMap = new Map<string, string>([
    ['Show/Hide', 'Visibility'],
    ['Scroll to', 'Scroll to it'],
  ]);
  const eventNameMapping = eventMap.get(eventName);
  return eventNameMapping || eventName;
};
export const elementHasVariant = (elementTag: string) => {
  return ['ProductVariants'].includes(elementTag);
};

export const elementHasItem = (elementTag: string) => {
  return ['IconList', 'IconListV2', 'Tabs', 'Carousel', 'Accordion'].includes(elementTag);
};

const getHoverTriggerLabel = (elementTag: string) => {
  return elementTag === 'ProductList' ? 'When hover a product in list' : `When hover item`;
};

const getItemSelectedTriggerLabel = (elementTag: string, condition?: InteractionCondition, selectedLabel?: string) => {
  if (elementTag === 'ProductVariants') {
    return `When click on option`;
  } else if (elementTag === 'Tabs') {
    return `Active tab ${selectedLabel || ''}`;
  } else {
    return `Select slide ${selectedLabel || ''}`;
  }
};

const getFinishLoadingTriggerLabel = (condition?: InteractionCondition) => {
  const data = condition?.data;
  return `After finish loading ${+data ? `${+data}s` : ''}`;
};

const getPopupTriggerLabel = (condition?: InteractionCondition, controlType?: ControlType) => {
  const data = condition?.data;
  const popupType = controlType === ControlType.POPUP_OPEN ? 'opening' : 'closing';
  return `After ${popupType} ${+data ? `${+data}s` : ''}`;
};
const checkNotEntirePageControlType = (controlType: ControlType) => {
  return ![ControlType.SCROLL_UP, ControlType.SCROLL_DOWN, ControlType.FINISH_LOADING].includes(controlType);
};

const checkEntirePage = (data: InteractionTarget | InteractionTrigger) => {
  if (data?.type === 'PAGE') return true;
  return false;
};

export const composeTriggerLabel = (trigger: Interaction['self'], elementName: string, selectedLabel?: string) => {
  const isEntirePage = checkEntirePage(trigger as InteractionTrigger);
  const controlType = trigger?.controlType;
  const elementTag = trigger?.elementTag || '';
  const condition = trigger?.condition;
  if (!controlType || (!elementTag && !isEntirePage)) return '';
  if (isEntirePage && checkNotEntirePageControlType(controlType)) return '';
  const triggerLabelMap = new Map<ControlType, () => string>([
    [ControlType.CLICK, () => `When click on element`],
    [ControlType.ITEM_HOVERED, () => getHoverTriggerLabel(elementTag)],
    [ControlType.MOUSE_OVER, () => `When hover element`],
    [ControlType.MOUSE_ENTER, () => `When hover element`],
    [ControlType.QUANTITY_CHANGE, () => getQuantityTriggerLabel(elementTag, condition, 'quantity')],
    [ControlType.PRICE_CHANGE, () => getQuantityTriggerLabel(elementTag, condition, 'price')],
    [ControlType.COMPARE_PRICE_CHANGE, () => getQuantityTriggerLabel(elementTag, condition, 'compare price')],
    [ControlType.IN_STOCK, () => getInStockTriggerLabel(condition)],
    [ControlType.OUT_OF_STOCK, () => `When out of stock`],
    [ControlType.REMAINING_TIME, () => getRemainingTimeTriggerLabel(condition)],
    [ControlType.ITEM_CLICK, () => `When click on item`],
    [ControlType.ITEM_SELECTED, () => getItemSelectedTriggerLabel(elementTag, condition, selectedLabel)],
    [ControlType.SUBMITTED, () => getSubmittedTriggerLabel(condition)],
    [ControlType.VARIANT_HOVERED, () => `When hover option`],
    [ControlType.VARIANT_CLICKED, () => `When click on option`],
    [ControlType.EXPAND_ITEM, () => `When expand item trigger`],
    [ControlType.POPUP_OPEN, () => getPopupTriggerLabel(condition, controlType)],
    [ControlType.POPUP_CLOSE, () => getPopupTriggerLabel(condition, controlType)],
    [ControlType.SCROLL_UP, () => 'When scroll up'],
    [ControlType.SCROLL_DOWN, () => 'When scroll down'],
    [ControlType.TAB_ACTIVE, () => `When active tab trigger`],
    [ControlType.SELECT_SLIDE, () => `When select slide trigger`],
    [ControlType.FINISH_LOADING, () => getFinishLoadingTriggerLabel(condition)],
  ]);

  const getLabelFunction = triggerLabelMap.get(controlType);

  return getLabelFunction ? getLabelFunction() : elementName;
};

const getClickTriggerSubInfo = (event?: string) => {
  return event === RollbackEvent.SECOND_CLICK ? 'Reverse interaction if click again' : undefined;
};

const getHoverTriggerSubInfo = (event?: string) => {
  return event === RollbackEvent.MOUSE_LEAVE ? 'Reverse interaction if mouse out' : '';
};

const getSubmittedTriggerLabel = (condition?: InteractionCondition) => {
  const submittedData = condition?.data;
  if (Number(submittedData) > 0) {
    return `After submitted ${submittedData || 0}s`;
  }
  return 'After submitted';
};

const getQuantityTriggerLabel = (elementTag?: string, condition?: InteractionCondition, conditionName?: string) => {
  const quantityData = condition?.data;
  const quantityType = condition?.type;
  if (!quantityData || !quantityType) return '';
  const currency = elementTag === 'ProductPrice' ? '$' : '';
  switch (quantityType) {
    case ConditionQuantity.EQUAL:
      return `When ${conditionName} is ${quantityData}${currency}`;
    case ConditionQuantity.GREATER:
      return `When ${conditionName} above ${quantityData}${currency}`;
    case ConditionQuantity.LESS:
      return `When ${conditionName} below ${quantityData}${currency}`;
    case ConditionQuantity.GREATER_OR_EQUAL:
      return `If ${conditionName} from ${quantityData}${currency} & above`;
    case ConditionQuantity.LESS_OR_EQUAL:
      return `If ${conditionName} from 0 to ${quantityData}${currency}`;
    case ConditionQuantity.BETWEEN:
      return `If ${conditionName} from ${quantityData?.from || 0} to ${quantityData?.to || 15}${currency}`;
    default:
      return '';
  }
};

const getInStockTriggerLabel = (condition?: InteractionCondition) => {
  const quantityData = condition?.data;
  const quantityType = condition?.type;
  if (!quantityData || !quantityType) return '';

  switch (quantityType) {
    case ConditionQuantity.EQUAL:
      return `Remaining stock is ${quantityData}`;
    case ConditionQuantity.GREATER:
      return `Remaining stock above ${quantityData}`;
    case ConditionQuantity.LESS:
      return +quantityData - 1 <= 1
        ? 'Remaining stock is 1'
        : `Remaining stock from 1 to ${parseInt(quantityData) - 1}`;
    case ConditionQuantity.GREATER_OR_EQUAL:
      return `Remaining stock from ${quantityData} & above`;
    case ConditionQuantity.LESS_OR_EQUAL:
      return parseInt(quantityData) <= 1 ? 'Remaining stock is 1' : `Remaining stock from 1 to ${quantityData}`;
    case ConditionQuantity.BETWEEN:
      return `Remaining stock from ${quantityData?.from || 0} to ${quantityData?.to || 15}`;
    default:
      return '';
  }
};

const getRemainingTimeTriggerLabel = (condition?: InteractionCondition) => {
  const timeData = condition?.metaData?.countdown;
  if (!timeData) return '';

  const day = Number.parseInt(timeData.day) || 0;
  const hour = Number.parseInt(timeData.hour) || 0;
  const minute = Number.parseInt(timeData.minute) || 0;
  const second = Number.parseInt(timeData.second) || 0;
  if (!day && !hour && !minute && !second) {
    return 'When time out';
  } else {
    return `Remaining time is ${day}d ${hour}h ${minute}m ${second}s`;
  }
};

export const composeTriggerSubInfo = (trigger: Interaction['self']) => {
  const isEntirePage = checkEntirePage(trigger as InteractionTrigger);
  const controlType = trigger?.controlType;
  if (!controlType) return undefined;
  if (isEntirePage && checkNotEntirePageControlType(controlType)) return undefined;

  const subTriggerInfoMap = new Map<ControlType, () => string | undefined>([
    [ControlType.CLICK, () => getClickTriggerSubInfo(trigger.rollbackEvent?.event)],
    [ControlType.ITEM_CLICK, () => getClickTriggerSubInfo(trigger.rollbackEvent?.event)],
    [ControlType.MOUSE_OVER, () => getHoverTriggerSubInfo(trigger.rollbackEvent?.event)],
    [ControlType.MOUSE_ENTER, () => getHoverTriggerSubInfo(trigger.rollbackEvent?.event)],
    [ControlType.ITEM_HOVERED, () => getHoverTriggerSubInfo(trigger.rollbackEvent?.event)],
    [ControlType.VARIANT_HOVERED, () => getHoverTriggerSubInfo(trigger.rollbackEvent?.event)],
    [ControlType.VARIANT_CLICKED, () => getClickTriggerSubInfo(trigger.rollbackEvent?.event)],
    [ControlType.ITEM_SELECTED, () => getItemsSelectedTriggerSubInfo(trigger.elementTag || '', trigger.condition)],
    [ControlType.SCROLL_DOWN, () => getScrollTriggerSubInfo(trigger.rollbackEvent?.event, trigger.event)],
    [ControlType.SCROLL_UP, () => getScrollTriggerSubInfo(trigger.rollbackEvent?.event, trigger.event)],
    [ControlType.FINISH_LOADING, () => getFinishLoadingTriggerSubInfo(trigger.condition)],
  ]);
  const getSubInfoFunction = subTriggerInfoMap.get(controlType);

  return getSubInfoFunction ? getSubInfoFunction() : undefined;
};

const getFinishLoadingTriggerSubInfo = (condition?: InteractionCondition) => {
  const data = condition?.metaData?.param;
  return data ? `If param is "${data}"` : '';
};
const getScrollTriggerSubInfo = (rollbackEvent?: string, event?: string) => {
  if (rollbackEvent === TriggerEvent.SCROLL_DOWN && event === TriggerEvent.SCROLL_UP)
    return 'Reverse interaction if scroll down';
  if (rollbackEvent === TriggerEvent.SCROLL_UP && event === TriggerEvent.SCROLL_DOWN)
    return 'Reverse interaction if scroll up';
  return '';
};

const getItemsSelectedTriggerSubInfo = (elementTag: string, condition?: InteractionCondition) => {
  const data = condition?.data;
  if (elementTag === 'ProductVariants') {
    if (data?.inStock === false) return 'And variant is out of stock';
  }
};

export const getSelectedProductVariant = (triggerCondition?: InteractionCondition) => {
  if (triggerCondition?.data && triggerCondition?.data.optionName !== 'none') {
    return `${triggerCondition.data?.optionName} ${triggerCondition.data?.optionValue || ''}`;
  }

  return '';
};
export const getDefaultValueForTextTarget = (componentData: {
  componentStyles: Record<string, any>;
  componentSettings: Record<string, any>;
  componentTag: string;
}) => {
  const { componentStyles, componentSettings, componentTag } = componentData || {};
  switch (componentTag) {
    case 'ProductTitle':
    case 'ProductPrice':
      return {
        ...componentStyles?.typo,
        attrs: {
          ...componentStyles?.typo?.attrs,
          color: componentStyles?.color?.normal,
        },
      };

    case 'ProductDescription':
      return {
        ...componentSettings?.typo,
        attrs: {
          ...componentSettings?.typo?.attrs,
          color: componentSettings?.color,
        },
      };

    case 'Button':
    case 'ProductButton':
    case 'DynamicCheckout':
    case 'ProductViewMore':
      return {
        ...componentStyles?.typo,
        attrs: {
          ...componentStyles.typo?.attrs,
          color: componentStyles?.textColor?.normal,
        },
      };

    default:
      return componentStyles?.typo;
  }
};

export const getDefaultValueForBgTarget = (componentData: {
  componentStyles: Record<string, any>;
  componentTag: string;
}) => {
  const { componentStyles, componentTag } = componentData;
  switch (componentTag) {
    case 'Button':
    case 'ProductButton':
    case 'DynamicCheckout':
    case 'ProductViewMore':
      return {
        backgroundColor: componentStyles?.backgroundColor?.normal,
        border: componentStyles?.borderBtn?.normal,
        borderRadius: componentStyles?.roundedBtn?.normal,
        hasShadow: componentStyles?.hasBoxShadowBtn?.normal,
        boxShadow: componentStyles?.boxShadowBtn?.normal,
      };

    case 'Section':
    case 'Row':
      return {
        ...DEFAULT_BG_TARGET,
        backgroundColor: componentStyles?.background?.desktop?.color,
      };
    default:
      return DEFAULT_BG_TARGET;
  }
};

export const getDefaultValueForImageTarget = (componentData: {
  componentStyles: Record<string, any>;
  componentSettings: Record<string, any>;
}) => {
  const { componentStyles, componentSettings } = componentData || {};
  return {
    bgi: componentSettings?.image,
    bgSize: componentStyles?.objectFit?.desktop,
  };
};

export function convertToSeconds(time: Record<string, string>) {
  const { day, hour, minute, second } = time || {};
  const daysInSeconds = Number(day ?? '0') * 24 * 60 * 60;
  const hoursInSeconds = Number(hour ?? '0') * 60 * 60;
  const minutesInSeconds = Number(minute ?? '0') * 60;
  const seconds = Number(second ?? '0');
  return daysInSeconds + hoursInSeconds + minutesInSeconds + seconds;
}

export const checkDisableEventTarget = (params: {
  events?: InteractionTargetEventObject[];
  eventCurr: string;
  elementTag?: string;
}) => {
  const { events, eventCurr, elementTag } = params;
  if (!events || !elementTag) return false;
  const elementDisableTarget = ['Dialog', 'ProductImagesV2', 'Carousel'];
  if (elementDisableTarget.includes(elementTag)) {
    return events.find((el) => {
      const targetsDisable = TOGGLE_DISABLED_EVENTS[el.event] || [];
      if (targetsDisable.length > 0) return el.event === eventCurr || targetsDisable.includes(eventCurr);
      return el.event === eventCurr;
    });
  }
  return events.find((el) => el.event === eventCurr);
};

export const convertFromSeconds = (seconds: number) => {
  const day = Math.floor(seconds / (24 * 3600));
  seconds %= 24 * 3600;
  const hour = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minute = Math.floor(seconds / 60);
  const second = seconds % 60;
  return { day: String(day), hour: String(hour), minute: String(minute), second: String(second) };
};

export const escapeQuotes = (input = '') => {
  return input.replace(/"/g, '\\"');
};
