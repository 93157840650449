import type { TriggerEvent } from '../constants';
import { getSelectedProductVariant } from '../helpers';
import type { InteractionElement, InteractionCondition } from '../types';

const composeTitleVariant = (title: string, condition?: InteractionCondition) => {
  return title.replace('${name}', getSelectedProductVariant(condition));
};

const composeInteractionHasItem = (title: string, condition?: InteractionCondition) => {
  return condition?.data !== 'none' ? title.replace('${name}', condition?.optionName || '') : '';
};

const composeTitleProductList = (title: string, condition?: InteractionCondition) => {
  return condition?.data !== 'none' ? title.replace('${name}', 'a product') : '';
};

export const useInteractionListing = () => {
  const composeTriggerTitle = (
    triggerData?: InteractionElement<TriggerEvent, InteractionCondition>,
    content?: string,
  ): string => {
    let title = content || '';
    const condition = triggerData?.condition;
    if (!triggerData || !triggerData.elementTag || !condition) return title;

    const tagsWithOptionName = [
      'IconListV2',
      'IconList',
      'Tabs',
      'Carousel',
      'Accordion',
      'FormCheckbox',
      'FormDropdown',
    ];

    if (triggerData.elementTag === 'ProductVariants') {
      title = composeTitleVariant(title, condition);
    } else if (tagsWithOptionName.includes(triggerData.elementTag)) {
      title = composeInteractionHasItem(title, condition);
    } else if (triggerData.elementTag === 'ProductList') {
      title = composeTitleProductList(title, condition);
    }
    return title;
  };

  return {
    composeTriggerTitle,
  };
};
