import type { ComponentTag } from '@gem/control';
import useBuilderConfigStore from '@/modules/editor/modules/component-preset/stores/builderConfig';
import type { TriggerEvent } from '../constants';
import {
  ControlType,
  customSubTitleMapping,
  targetEventMapping,
  triggerEventEntirePage,
  triggerEventMapping,
} from '../constants';
import { conditionMappingElementTag, triggerConditionOptions } from '../constants/condition';
import type { InteractionCondition, InteractionElement, InteractionTarget, InteractionTrigger } from '../types';
import { useInteractionListing } from './useInteractionListing';

export const useBuildDataMapping = () => {
  const builderConfigStore = useBuilderConfigStore();
  const { composeTriggerTitle } = useInteractionListing();

  const getElementNameByTag = (tag?: ComponentTag) => {
    if (!tag) return '';
    const component = builderConfigStore.getBuilderSettingByTag(tag);
    return component.customLabel || component.label || component.tag;
  };

  const checkEntirePage = (data: InteractionTarget | InteractionTrigger) => {
    if (data?.type === 'PAGE') return true;
    return false;
  };

  const checkNotEntirePageControlType = (controlType: ControlType) => {
    return ![ControlType.SCROLL_UP, ControlType.SCROLL_DOWN, ControlType.FINISH_LOADING].includes(controlType);
  };

  const getTriggerEvents = (elementTag: string | undefined, type?: 'PAGE' | 'ELEMENT') => {
    if (type === 'PAGE') return triggerEventEntirePage;
    if (!elementTag) return triggerEventMapping.general;
    return triggerEventMapping[elementTag] || triggerEventMapping.general;
  };

  const getTargetEvents = (elementTag?: string) => {
    if (!elementTag) return [];
    return targetEventMapping[elementTag] || targetEventMapping.general;
  };

  const getConditionQuantityByElementTag = (elementTag?: ComponentTag) => {
    if (!elementTag) return '';
    const conditionOfElement = conditionMappingElementTag[elementTag] || [];
    return triggerConditionOptions.filter((el) => conditionOfElement.includes(el.id));
  };

  const composeTargetEvent = (triggerData?: InteractionElement<TriggerEvent, InteractionCondition>) => {
    if (!triggerData?.event) return '';
    const triggerByTag = getTriggerEvents(triggerData?.elementTag);
    const isEntirePage = checkEntirePage(triggerData);

    const key = triggerData?.controlType || triggerData?.event || '';
    if (isEntirePage && checkNotEntirePageControlType(key as ControlType)) return '';
    const content = triggerByTag.find((el) => el.id === triggerData?.event)?.['subTitle'] || customSubTitleMapping[key];
    return [ControlType.CLICK, ControlType.MOUSE_OVER, ControlType.MOUSE_ENTER].includes(key as ControlType)
      ? content
      : composeTriggerTitle(triggerData, content);
  };

  return {
    getTriggerEvents,
    getElementNameByTag,
    getTargetEvents,
    getConditionQuantityByElementTag,
    checkEntirePage,
    checkNotEntirePageControlType,
    composeTargetEvent,
  };
};
