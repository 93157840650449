import { defineStore } from 'pinia';
import type { Maybe, ScreenType } from '@/types/custom';
import type { ThemePage, ThemePageType } from '@/types/graphql';

export type State = {
  init: {
    loading: {
      sections: boolean;
      pushSectionToStore: boolean;
      template: boolean;
      globalStyle: boolean;
      tryBuild: boolean;
      message: string[];
      skeleton: boolean;
    };
    recovery: boolean;
    isShowMakeDefaultHomePageConfirmation: boolean;
    isShowMakeDefaultCollectionPageConfirmation: boolean;
    isShowMakeDefaultProductPageConfirmation: boolean;
    isDiscardChange: boolean;
    isUpgradingNewVersion: boolean;
    isConnectToShopifyModal: boolean;
    isGetStartedConnectShopifyModal: boolean;
  };
  isFullScreen: boolean;
  autoSaveEnable: boolean;
  isPublishingFromGemX: boolean;
  isOpenCustomCode: boolean;
  isReplaceCurrentHomePage: boolean;
  isPublishingFromPopup: boolean;
  isShowSwitchToAssignedPopup: boolean;
  isDisplayedSwitchToAssignedPopup: boolean;
  editing: {
    themeId: string;
    pageId: string;
    pageType: ThemePageType | 'THEME_SECTION' | null;
    pageDefault: boolean;
    sectionCid: string | null;
    publishedAt: string | null;
    componentUid: string | null;
    sectionHoverCid: string | null;
    activeTabId: 'setting' | 'style' | 'advanced';
    componentHoverUid: string | null;
    componentDbClickUid: string | null;
    numberClick: number;
    isAllowActiveParentsDropdown: boolean;
    addSectionPopupState: {
      open: boolean;
      hidePosition: string;
    };
    productId?: string;
    articleId?: string;
    wrapProductUid?: string;
    productPostPurchaseUid?: string;
    wrapArticleUid?: string;
    marqueeSelectedKey?: string;
    isFocusedTextEditor?: boolean;
    textChildComponentUid?: string | null;
    isClickEditingText?: boolean | null;
    isEditThemeSection?: boolean | null;
    pageStatus: string;
    lastComponent: {
      tag: string | null;
      uid: string | null;
      scrollTop: number | 0;
    };
    platformOriginalTemplate: Record<string, any>;
    isFocusedInputOrTextarea?: boolean;
    pageParentId?: string;
    pageParentDefault?: boolean;
  };
  screen: {
    activeId: ScreenType;
    isActiveTabletMobile: boolean;
  };
  iframe: {
    loaded: boolean;
    overlay: boolean;
  };
  layer: {
    open: boolean;
  };
  mobileOnly?: Maybe<boolean>;
  isDragging: boolean;
  searchKeyword: string | undefined;
  modalActiveId?: string;
  saving: boolean;
  saveDone: boolean;
  layoutSetting: {
    showHeader: boolean;
    showFooter: boolean;
  };
  dataStyleCopied?: {
    tag: string;
    advanced?: Record<string, any> | undefined;
    styles?: Record<string, any> | undefined;
  };
  ignoreGlobalKeyboardRemove: boolean;
  buildWithSectionMode: boolean;
  buildWithSectionModeBeforeSearch: boolean;
  customFontIDs: string[];
  listCustomFontId: string[];
  pageStatus: ThemePage['status'];
  sidebarWarningSalesFunnels: boolean;
  isEnabledThemeExtension: boolean;
  forceExitEditor: boolean;
  isHideUpdateChangesFromShopifyPopup: boolean;
  isShowRenameModal: boolean;
  isShowPublishConfirm: boolean;
};

const defaultEditing: State['editing'] = {
  themeId: '',
  pageId: '',
  pageType: null,
  pageDefault: false,
  sectionCid: null,
  componentUid: null,
  publishedAt: null,
  activeTabId: 'setting',
  componentHoverUid: null,
  sectionHoverCid: null,
  componentDbClickUid: null,
  isAllowActiveParentsDropdown: false,
  numberClick: 1,
  addSectionPopupState: {
    open: false,
    hidePosition: '',
  },
  isFocusedTextEditor: false,
  textChildComponentUid: '',
  pageStatus: '',
  lastComponent: {
    tag: null,
    uid: null,
    scrollTop: 0,
  },
  platformOriginalTemplate: {},
  isFocusedInputOrTextarea: false,
};

export const useEditorStore = defineStore('editor', {
  state: (): State => ({
    init: {
      loading: {
        sections: true,
        pushSectionToStore: false,
        template: true,
        globalStyle: true,
        tryBuild: false,
        message: [],
        skeleton: true,
      },
      recovery: false,
      isDiscardChange: false,
      isShowMakeDefaultHomePageConfirmation: false,
      isShowMakeDefaultCollectionPageConfirmation: false,
      isShowMakeDefaultProductPageConfirmation: false,
      isUpgradingNewVersion: false,
      isConnectToShopifyModal: false,
      isGetStartedConnectShopifyModal: false,
    },
    isShowSwitchToAssignedPopup: false,
    isDisplayedSwitchToAssignedPopup: false,
    isPublishingFromPopup: false,
    isPublishingFromGemX: false,
    isReplaceCurrentHomePage: false,
    isOpenCustomCode: false,
    mobileOnly: false,
    isFullScreen: false,
    saveDone: false,
    autoSaveEnable: false,
    editing: {
      ...defaultEditing,
    },
    screen: {
      activeId: 'desktop',
      isActiveTabletMobile: false,
    },
    iframe: {
      loaded: false,
      overlay: false,
    },
    layer: {
      open: false,
    },
    isDragging: false,
    searchKeyword: '',
    modalActiveId: '',
    saving: false,
    layoutSetting: {
      showHeader: false,
      showFooter: false,
    },
    dataStyleCopied: undefined,
    ignoreGlobalKeyboardRemove: false,
    buildWithSectionMode: false,
    buildWithSectionModeBeforeSearch: false,
    customFontIDs: [],
    listCustomFontId: [],
    pageStatus: 'DRAFT',
    sidebarWarningSalesFunnels: false,
    isEnabledThemeExtension: false,
    forceExitEditor: false,
    isHideUpdateChangesFromShopifyPopup: false,
    isShowRenameModal: false,
    isShowPublishConfirm: false,
  }),
  getters: {
    getInitUpgradingNewVersion(state) {
      return state.init.isUpgradingNewVersion;
    },
    getInitLoadingSections(state) {
      return state.init.loading.sections;
    },
    getInitLoadingTemplate(state) {
      return state.init.loading.template;
    },
    getInitLoadingGlobalStyle(state) {
      return state.init.loading.globalStyle;
    },
    getInitConnectToShopifyModal(state) {
      return state.init.isConnectToShopifyModal;
    },
    getGetStartedInitConnectToShopifyModal(state) {
      return state.init.isGetStartedConnectShopifyModal;
    },
    getEditingThemeId(state) {
      return state.editing.themeId;
    },
    getEditingPageId(state) {
      return state.editing.pageId;
    },
    getEditingSectionCid(state) {
      return state.editing.sectionCid;
    },
    getEditingComponentUid(state) {
      return state.editing.componentUid;
    },
    getEditingPublishedAt(state) {
      return state.editing.publishedAt;
    },
    getComponentHoverUid(state) {
      return state.editing.componentHoverUid;
    },
    getEditingActiveTabId(state) {
      return state.editing.activeTabId;
    },
    getIframeLoaded(state) {
      return state.iframe.loaded;
    },
    getInitLoaded(state) {
      return state.init.loading.pushSectionToStore && !state.init.loading.template && !state.init.loading.globalStyle;
    },
    getLoadingTryBuild(state) {
      return state.init.loading.tryBuild;
    },
    getScreenActiveId(state) {
      return state.screen.activeId;
    },
    getDragging(state) {
      return state.isDragging;
    },
    getIframeOverlay(state) {
      return state.iframe.overlay;
    },
    getLayerOpen(state) {
      return state.layer.open;
    },
    getInitRecovery(state) {
      return state.init.recovery;
    },
    getInitIsDiscardChange(state) {
      return state.init.isDiscardChange;
    },
    getIsShowMakeDefaultHomePageConfirmation(state) {
      return state.init.isShowMakeDefaultHomePageConfirmation;
    },
    getIsShowMakeDefaultProductPageConfirmation(state) {
      return state.init.isShowMakeDefaultProductPageConfirmation;
    },
    getIsShowMakeDefaultCollectionPageConfirmation(state) {
      return state.init.isShowMakeDefaultCollectionPageConfirmation;
    },
    getIsShowSwitchToAssignedPopup(state) {
      return state.isShowSwitchToAssignedPopup;
    },
    getIsDisplayedSwitchToAssignedPopup(state) {
      return state.isDisplayedSwitchToAssignedPopup;
    },
    getEditingPageType(state) {
      return state.editing.pageType;
    },
    getEditingPageDefault(state) {
      return state.editing.pageDefault;
    },
    getEditingPlatformOriginalTemplate(state) {
      return state.editing.platformOriginalTemplate;
    },
    getEditingPageStatus(state) {
      return state.editing.pageStatus;
    },
    getEditingPagePublished(state) {
      return (
        ['PUBLISHED', 'NEED_PUBLISHING', 'PUBLISHING', 'PUBLISH_ERROR'].includes(state.pageStatus ?? '') &&
        state.editing.publishedAt !== null &&
        state.editing.publishedAt?.length > 0
      );
    },
    getEditingPageDraft(state) {
      return (
        state.pageStatus === 'DRAFT' ||
        (['PUBLISHED', 'NEED_PUBLISHING', 'PUBLISHING', 'PUBLISH_ERROR'].includes(state.pageStatus ?? '') &&
          !state.editing.publishedAt)
      );
    },
    getSaving(state) {
      return state.saving;
    },
    getComponentDbClickUid(state) {
      return state.editing.componentDbClickUid;
    },
    getBuildWithSectionMode(state) {
      return state.buildWithSectionMode;
    },
    getBuildWithSectionModeBeforeSearch(state) {
      return state.buildWithSectionModeBeforeSearch;
    },
    getLayoutSetting(state: State) {
      return state.layoutSetting;
    },
    getEditingNumberClick(state) {
      return state.editing.numberClick;
    },
    getEditingProductId(state) {
      return state.editing.productId;
    },
    getEditingArticleId(state) {
      return state.editing.articleId;
    },
    getEditingWrapProductUid(state) {
      return state.editing.wrapProductUid;
    },
    getEditingWrapArticleUid(state) {
      return state.editing.wrapArticleUid;
    },
    getEditingProductPostPurchaseUid(state) {
      return state.editing.productPostPurchaseUid;
    },
    getIsActiveTabletMobile(state) {
      return state.screen.isActiveTabletMobile;
    },
    getIsAllowActiveParentsDropdown(state) {
      return state.editing.isAllowActiveParentsDropdown;
    },
    getIsFocusedTextEditor(state) {
      return state.editing.isFocusedTextEditor;
    },
    getTextChildComponentUid(state) {
      return state.editing.textChildComponentUid;
    },
    getIsClickEditingText(state) {
      return state.editing.isClickEditingText;
    },
    getLastEditComponent(state) {
      return state.editing.lastComponent;
    },
    getEditingPageParentId(state) {
      return state.editing.pageParentId;
    },
    getEditingPageParentDefault(state) {
      return state.editing.pageParentDefault;
    },
    getDataStyleCopied(state) {
      return state.dataStyleCopied;
    },
    getModalActiveId(state) {
      return state.modalActiveId;
    },
    getIsOpenCustomCode(state) {
      return state.isOpenCustomCode;
    },
    getIsEditThemeSection(state) {
      return state.editing.isEditThemeSection;
    },
    getSaveDone(state) {
      return state.saveDone;
    },
    getAutoSaveEnable(state) {
      return state.autoSaveEnable;
    },
    getIgnoreGlobalKeyboardRemove(state) {
      return state.ignoreGlobalKeyboardRemove;
    },
    getIsReplaceCurrentHomePage(state) {
      return state.isReplaceCurrentHomePage;
    },
    getIsPublishingFromPopup(state) {
      return state.isPublishingFromPopup;
    },
    getIsPublishingFromGemX(state) {
      return state.isPublishingFromGemX;
    },
    getcustomFontIDs(state) {
      return state.customFontIDs;
    },
    getListCustomFontId(state) {
      return state.listCustomFontId;
    },
    getLastEditComponentScrollTop(state) {
      return state.editing.lastComponent.scrollTop;
    },
    getPageStatus(state) {
      return state.pageStatus;
    },
    getSidebarWarningSalesFunnels(state) {
      return state.sidebarWarningSalesFunnels;
    },
    getEnabledThemeExtension(state) {
      return state.isEnabledThemeExtension;
    },
    getForceExitEditor(state) {
      return state.forceExitEditor;
    },
    getIsHideUpdateChangesFromShopifyPopup(state) {
      return state.isHideUpdateChangesFromShopifyPopup;
    },
    getIsShowRenameModal(state) {
      return state.isShowRenameModal;
    },
    getMarqueeKey(state) {
      return state.editing.marqueeSelectedKey;
    },
    getIsFocusedInputOrTextarea(state) {
      return state.editing.isFocusedInputOrTextarea;
    },
    getIsShowPublishConfirm(state) {
      return state.isShowPublishConfirm;
    },
    getSkeletonLoading(state) {
      return state.init.loading.skeleton;
    },
    getIsPageIdValid(state) {
      return !!state.editing.pageId && state.editing.pageId !== '';
    },
  },
  actions: {
    clear() {
      this.$reset();
    },
    clearEditing() {
      this.editing = defaultEditing;
    },
    setInitUpgradingNewVersion(val: boolean) {
      this.init.isUpgradingNewVersion = val;
    },
    setKeyword(input?: string) {
      this.searchKeyword = input;
    },
    setEditingThemeId(themeId: string) {
      this.editing.themeId = themeId;
    },
    setEditingPageId(pageId: string) {
      this.editing.pageId = pageId;
    },
    setActiveScreenId(screenId: ScreenType) {
      if ((screenId == 'tablet' || screenId == 'mobile') && !this.screen.isActiveTabletMobile) {
        this.screen.isActiveTabletMobile = true;
      }
      if (this.mobileOnly) {
        this.screen.activeId = 'desktop';
      } else {
        this.screen.activeId = screenId;
      }
    },
    setFullScreen(value: boolean) {
      this.isFullScreen = value;
    },
    setOverlayIframe(value: boolean) {
      this.iframe.overlay = value;
    },
    setDragging(value: boolean) {
      this.isDragging = value;
    },
    setEditingSectionCid(sectionCid: string | null) {
      this.editing.sectionCid = sectionCid;
    },
    setEditingComponentUid(componentUid: string | null) {
      this.editing.componentUid = componentUid;
    },
    setEditingPublishedAt(publishedAt: string | null) {
      this.editing.publishedAt = publishedAt;
    },
    setComponentDbClickUid(componentUid: string | null) {
      this.editing.componentDbClickUid = componentUid;
    },
    setComponentHoverUid(componentUid: string | null) {
      this.editing.componentHoverUid = componentUid;
    },
    setSectionHoverCid(sectionCid: string | null) {
      this.editing.sectionHoverCid = sectionCid;
    },
    setEditingActiveTabId(tabId: 'setting' | 'style' | 'advanced') {
      this.editing.activeTabId = tabId;
    },
    setEditingProductId(productId?: string) {
      this.editing.productId = productId;
    },
    setEditingArticleId(articleId: string) {
      this.editing.articleId = articleId;
    },
    setEditingMarqueeSelectedKey(key?: string) {
      this.editing.marqueeSelectedKey = key;
    },
    setEditingWrapProductUid(productUid: string) {
      this.editing.wrapProductUid = productUid;
    },
    setEditingProductPostPurchaseUid(productUid: string) {
      this.editing.productPostPurchaseUid = productUid;
    },
    setEditingWrapArticleUid(articleUid: string) {
      this.editing.wrapArticleUid = articleUid;
    },
    setLastEditComponent(tag: string | null, uid: string | null) {
      this.editing.lastComponent = { ...this.editing.lastComponent, tag, uid };
    },
    setLastEditComponentScrollTop(scrollTop: number | 0) {
      this.editing.lastComponent.scrollTop = scrollTop;
    },
    setInitLoadingSections(value: boolean) {
      this.init.loading.sections = value;
    },
    setInitPushSectionToStore(value: boolean) {
      this.init.loading.pushSectionToStore = value;
    },
    setInitLoadingTemplate(value: boolean) {
      this.init.loading.template = value;
    },
    setInitLoadingTryBuild(value: boolean) {
      this.init.loading.tryBuild = value;
    },
    setAddSectionPopup(value: boolean, hidePosition: string) {
      this.editing.addSectionPopupState = {
        open: value,
        hidePosition,
      };
    },
    setInitLoadingGlobalStyle(value: boolean) {
      this.init.loading.globalStyle = value;
    },
    setIframeLoaded(value: boolean) {
      this.iframe.loaded = value;
    },
    setLayerOpen(value: boolean) {
      this.layer.open = value;
    },
    setInitRecovery(value: boolean) {
      this.init.recovery = value;
    },
    setInitIsDiscardChange(value: boolean) {
      this.init.isDiscardChange = value;
    },
    setShowMakeDefaultHomePageConfirmation(value: boolean) {
      this.init.isShowMakeDefaultHomePageConfirmation = value;
    },
    setShowMakeDefaultProductPageConfirmation(value: boolean) {
      this.init.isShowMakeDefaultProductPageConfirmation = value;
    },
    setShowMakeDefaultCollectionPageConfirmation(value: boolean) {
      this.init.isShowMakeDefaultCollectionPageConfirmation = value;
    },
    setInitIsConnectToShopifyModal(value: boolean) {
      this.init.isConnectToShopifyModal = value;
    },
    setGetStartedInitIsConnectToShopifyModal(value: boolean) {
      this.init.isGetStartedConnectShopifyModal = value;
    },
    setMobileOnly(value?: Maybe<boolean>) {
      this.mobileOnly = value;
    },
    setLoadingMessage(value: string[]) {
      this.init.loading.message = value;
    },
    setEditingPageType(value: ThemePageType | 'THEME_SECTION' | null) {
      this.editing.pageType = value;
    },
    setEditingPageDefault(value: boolean) {
      this.editing.pageDefault = value;
    },
    setEditingPlatformOriginalTemplate(value: Record<string, any>) {
      this.editing.platformOriginalTemplate = value;
    },
    setEditingPageStatus(value: string) {
      this.editing.pageStatus = value;
    },
    setEditingPageParentId(value: string) {
      this.editing.pageParentId = value;
    },
    setEditingPageParentDefault(value: boolean) {
      this.editing.pageParentDefault = value;
    },
    setActiveModal(modalId?: string) {
      this.modalActiveId = modalId;
    },
    setSaving(value: boolean) {
      this.saving = value;
    },
    setLayoutSetting(value: { showHeader: boolean; showFooter: boolean }) {
      if (Object.keys(value).length === 0) return;
      this.layoutSetting = value;
    },
    setEditingNumberClick(value: number) {
      this.editing.numberClick = value;
    },
    setIsAllowActiveParentsDropdown(value: boolean) {
      this.editing.isAllowActiveParentsDropdown = value;
    },
    setIsFocusedTextEditor(value: boolean) {
      this.editing.isFocusedTextEditor = value;
    },
    setTextChildComponentUid(value: string) {
      this.editing.textChildComponentUid = value;
    },
    setIsClickEditingText(value: boolean) {
      this.editing.isClickEditingText = value;
    },
    setDataStyleCopied(value: State['dataStyleCopied']) {
      this.dataStyleCopied = value;
    },
    setIsOpenCustomCode(value: boolean) {
      this.isOpenCustomCode = value;
    },
    setIsEditThemeSection(value: boolean) {
      this.editing.isEditThemeSection = value;
    },
    setAutoSaveEnable(value: boolean) {
      this.autoSaveEnable = value;
    },
    setSaveDoneProgress() {
      this.saveDone = true;
      setTimeout(() => {
        this.saveDone = false;
      }, 2000);
    },
    setIgnoreGlobalKeyboardRemove(value: boolean) {
      this.ignoreGlobalKeyboardRemove = value;
    },
    setBuildWithSectionMode(value: boolean) {
      localStorage.setItem('build-with-section-mode', (+value).toString());
      this.buildWithSectionMode = value;

      if (this.searchKeyword?.length === 0) {
        this.buildWithSectionModeBeforeSearch = value;
      }
    },
    setIsReplaceCurrentHomePage(value: boolean) {
      this.isReplaceCurrentHomePage = value;
    },
    setIsPublishingFromPopup(value: boolean) {
      this.isPublishingFromPopup = value;
    },
    setIsPublishingFromGemX(value: boolean) {
      this.isPublishingFromGemX = value;
    },
    setCustomFontIDs(value: string[]) {
      this.customFontIDs = value;
    },
    setListCustomFontId(value: string[]) {
      this.listCustomFontId = value;
    },
    setIsShowSwitchToAssignedPopup(value: boolean) {
      this.isShowSwitchToAssignedPopup = value;
    },
    setIsDisplayedSwitchToAssignedPopup(value: boolean) {
      this.isDisplayedSwitchToAssignedPopup = value;
    },
    setPageStatus(value: ThemePage['status']) {
      this.pageStatus = value;
    },
    setSidebarWarningSalesFunnels(value: boolean) {
      this.sidebarWarningSalesFunnels = value;
    },
    setEnabledThemeExtension(value: boolean) {
      this.isEnabledThemeExtension = value;
    },
    setForceExitEditor(value: boolean) {
      this.forceExitEditor = value;
    },
    setIsHideUpdateChangesFromShopifyPopup(value: boolean) {
      this.isHideUpdateChangesFromShopifyPopup = value;
    },
    setIsShowRenameModal(value: boolean) {
      this.isShowRenameModal = value;
    },
    setIsFocusedInputOrTextarea(value: boolean) {
      this.editing.isFocusedInputOrTextarea = value;
    },
    setIsShowPublishConfirm(value: boolean) {
      this.isShowPublishConfirm = value;
    },
    setSkeletonLoading(value: boolean) {
      this.init.loading.skeleton = value;
    },
  },
});
export default useEditorStore;
